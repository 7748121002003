import ENV from 'src/app/configs/env';
import {TxType} from 'src/app/types/tx-type';
import {detectWeb3Object} from 'src/app/utils/helpers';
const TokenLockerABI = require('src/app/configs/ABIs/TokenLocker.json');
const ERC20ABI = require('src/app/configs/ABIs/ERC20.json');

const {web3} = detectWeb3Object();
const tokenLockerContract = new web3.eth.Contract(TokenLockerABI);

export async function fetchLockedAmount(address: string, contract: string): Promise<number> {
  tokenLockerContract.options.address = contract;

  const amount = await tokenLockerContract.methods.lockOf(address).call();

  return +amount;
}

export async function fetchClaimableAmount(address: string, contract: string): Promise<number> {
  tokenLockerContract.options.address = contract;

  const amount = await tokenLockerContract.methods.canUnlockAmount(address).call();

  return +amount;
}

export async function fetchFirstUnlockAmount(totalAmount: string, contract: string): Promise<number> {
  tokenLockerContract.options.address = contract;

  const amount = await tokenLockerContract.methods.firstUnlockAmount(totalAmount).call();

  return +amount;
}

export async function fetchReleasedAmount(address: string, contract: string): Promise<number> {
  tokenLockerContract.options.address = contract;

  const amount = await tokenLockerContract.methods.released(address).call();

  return +amount;
}

export function getFirstUnlockTxObject(address: string, index: number, account: string, amount: number, merkleProof: any, contract: string): TxType {
  const firstUnlockData = tokenLockerContract.methods.firstUnlock(index, account, amount, merkleProof).encodeABI();
  return _getTxObject(address, contract, firstUnlockData);
}

export function getUnlockTxObject(address: string, account: string, contract: string): TxType {
  const unlockData = tokenLockerContract.methods.unlock(account).encodeABI();
  return _getTxObject(address, contract, unlockData);
}

function _getTxObject(address: string, contract: string, methodData: string): TxType {
  return {
    from: address,
    to: contract,
    value: '0x0',
    data: methodData,
  };
}

export async function fetchContractBalance(address: string): Promise<number> {
  let contract = new web3.eth.Contract(ERC20ABI, ENV.TOKEN.ADDRESS);
  const balance = await contract.methods.balanceOf(address).call();
  // console.log('tokenLockerContract', tokenLockerContract);

  return +balance;
}

//getOwner
export async function getOwner(contract: string): Promise<string> {
  tokenLockerContract.options.address = contract;

  const owner = await tokenLockerContract.methods.owner().call();
  
  return owner;
}

export function getUpdateRootTxObject(address: string, newMerkleRoot: string, contract: string): TxType {
  const updateRootData = tokenLockerContract.methods.updateRoot(newMerkleRoot).encodeABI();
  return _getTxObject(address, contract, updateRootData);
}

export function getWithdrawTxObject(address: string, amount: string, contract: string): TxType {
  const withdrawData = tokenLockerContract.methods.emergencyWithdraw(ENV.TOKEN.ADDRESS, amount).encodeABI();
  return _getTxObject(address, contract, withdrawData);
}

export function getTransferOwnershipTxObject(address: string, newOwner: string, contract: string): TxType {
  const transferOwnershipData = tokenLockerContract.methods.transferOwnership(newOwner).encodeABI();
  return _getTxObject(address, contract, transferOwnershipData);
}

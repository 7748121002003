const env = {
  NETWORK_ID: 3,
  CHAIN_NAME: "Ropsten Test Network",
  URLS: {
    ETHERSCAN: "https://ropsten.etherscan.io/",
    API_SERVER: "http://localhost:8080",
  },
  NODE: {
    URL: "https://ropsten.infura.io/v3/161bb74e55ff44ef8fe6fc7c16ee1692",
  },
  TOKEN: {
    ADDRESS: '0xe0b638bb0c3c3ad0a7346986bbe3de668ee8200a'
  }
};

export default env;

const env = {
  NETWORK_ID: 1,
  CHAIN_NAME: 'mainnet',
  URLS: {
    ETHERSCAN: 'https://etherscan.io',
    API_SERVER: 'https://dapi.icetea.io/'
    // API_SERVER: 'http://localhost:8080',
  },
  NODE: {
    URL: 'https://mainnet.infura.io/v3/b14b5f54206846dcac9d091556a2a063',
  },
  TOKEN: {
    ADDRESS: '0x8b39b70e39aa811b69365398e0aace9bee238aeb'
  }
};

export default env;
